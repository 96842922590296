<template>
<div>
    <v-card-text class="py-0" style="height: 100%">
        <v-row no-gutters>
            <v-col cols="6" class="pb-0">
                <v-chip outlined class="px-0" style="border-left: none;">
                <v-chip outlined class="px-0" style="border-left: none;">
                <v-chip outlined class="px-0" style="border-left: none;">
                    <v-chip outlined  class="pl-3 pr-0">
                        <span class="mr-3" style="opacity: 0.7">Assigned User:</span>
                        <v-menu bottom offset-y :close-on-content-click="false" v-model="userModal">
                            <template v-slot:activator="{on, attrs}">
                                <div v-on="on" v-bind="attrs">
                                    <v-avatar color="secondary" size="32" class="mr-2">
                                        <span v-if="!selectedUser.avatar" class=" text-h7">{{$Format.avatarAbbreviation(selectedUser.fullName)}}</span>
                                        <img v-else :src="selectedUser.avatar" referrerpolicy="no-referrer" />
                                    </v-avatar>
                                    <span>{{ selectedUser.fullName }}</span>
                                    <v-icon small class="ml-2" style="rotate: -45deg;">swap_horiz</v-icon>
                                </div>
                            </template>
                                <v-card color="greyBase">
                                    <v-card-text class="pa-2">
                                        <v-text-field v-model="searchUsers" prepend-inner="search" dense hide-details outlined label="Search Users" style="width: 275px">
                                        </v-text-field>
                                        <div style="max-height: 40vh; overflow-y: scroll;">
                                            <v-list dense v-if="docsUsersFiltered.length > 0">
                                                <v-list-item dense v-for="user in docsUsersFiltered" :key="user.id" @click="changeUser(user)" style="width: 275px">
                                                    <v-list-item-avatar>
                                                        <v-avatar color="secondary" size="32">
                                                            <span v-if="!user.avatar" class=" text-h7">{{$Format.avatarAbbreviation(user.fullName)}}</span>
                                                            <img v-else :src="user.avatar" referrerpolicy="no-referrer" />
                                                        </v-avatar>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{user.fullName}}</v-list-item-title>
                                                        <v-list-item-subtitle>{{ user.emailAddress }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </div>
                                    </v-card-text>
                                </v-card>
                        </v-menu>
                        <v-tooltip>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon small @click="pinUser()" :disabled="pinUsersDisabled && !pinnedUsers.find(x => x.id == selectedUser.id)">
                                    <v-icon  small :color="pinnedUsers.find(x => x.id == selectedUser.id) ? 'tertiary' : ''">push_pin</v-icon>
                                </v-btn>
                            </template>
                            <span v-if="!pinUsersDisabled">Pin User</span>
                            <span v-if="pinUsersDisabled">Max Pinned Users</span>
                        </v-tooltip>
                    </v-chip>
                    <div v-if="pinnedUsers[0] && pinnedUsers[0].id != selectedUser.id" class="ml-3 mr-3" @click="changeUser(pinnedUsers[0])">
                        <v-avatar color="secondary" size="32" class="mr-2">
                            <span v-if="!pinnedUsers[0].avatar" class=" text-h7">{{$Format.avatarAbbreviation(pinnedUsers[0].fullName)}}</span>
                            <img v-else :src="pinnedUsers[0].avatar" referrerpolicy="no-referrer" />
                        </v-avatar>
                        <span>{{ pinnedUsers[0].fullName.split(" ")[0] }}</span>
                    </div>
                </v-chip>
                <div v-if="pinnedUsers[1] && pinnedUsers[1].id != selectedUser.id" class="ml-3 mr-3" @click="changeUser(pinnedUsers[1])">
                        <v-avatar color="secondary" size="32" class="mr-2">
                            <span v-if="!pinnedUsers[1].avatar" class=" text-h7">{{$Format.avatarAbbreviation(pinnedUsers[1].fullName)}}</span>
                            <img v-else :src="pinnedUsers[1].avatar" referrerpolicy="no-referrer" />
                        </v-avatar>
                        <span>{{ pinnedUsers[1].fullName.split(" ")[0] }}</span>
                    </div>
            </v-chip>
                <div v-if="pinnedUsers[2] && pinnedUsers[2].id != selectedUser.id" class="ml-3 mr-3" @click="changeUser(pinnedUsers[2])">
                        <v-avatar color="secondary" size="32" class="mr-2">
                            <span v-if="!pinnedUsers[2].avatar" class=" text-h7">{{$Format.avatarAbbreviation(pinnedUsers[2].fullName)}}</span>
                            <img v-else :src="pinnedUsers[2].avatar" referrerpolicy="no-referrer" />
                        </v-avatar>
                        <span>{{ pinnedUsers[2].fullName.split(" ")[0] }}</span>
                    </div>
            </v-chip>
            </v-col>
            <v-col cols="6" class="pb-0 d-flex justify-end">
                <v-chip class="pr-1 pl-2" outlined style="border:none" @click="viewUnprocessed = !viewUnprocessed, readyFilesKey++">
                    <span class="mr-2" style="font-size: 12px">Unprocessed Files:</span>
                    <v-chip pill outlined small>
                        <span v-if="!loadingCounts">{{ readyFiles.count }}</span>
                        <v-progress-circular indeterminate size="20" width="2" color="primary" v-if="loadingCounts"></v-progress-circular>
                    </v-chip>
                </v-chip>
                <v-chip class="pr-1 pl-2" outlined style="border:none" @click="viewNotReady = !viewNotReady">
                    <span class="mr-2" style="font-size: 12px">Not Ready to Invoice:</span>
                    <v-chip pill outlined small>
                        <span v-if="!loadingCounts">{{ notReadyInvoice.count }}</span>
                        <v-progress-circular indeterminate size="20" width="2" color="primary" v-if="loadingCounts" ></v-progress-circular>
                    </v-chip>
                </v-chip>
                <v-chip class="pr-1 pl-2" outlined style="border:none" @click="openFilesActive">
                    <span class="mr-2" style="font-size: 12px">Open Files:</span>
                    <v-chip pill outlined small>
                        <span v-if="!loadingCompletedFiles && !loadingFiles">{{ openFilesCount }}</span>
                        <v-progress-circular indeterminate size="20" width="2" color="primary" v-if="loadingCompletedFiles || loadingFiles" ></v-progress-circular>
                    </v-chip>   
                </v-chip>
            </v-col>
        </v-row>
        <div class="d-flex align-center">
            <div class="d-flex align-center">
                <v-chip small :outlined="!fivePlus" color="redPop" @click="switchFilter('fivePlus')" class="px-1 mr-2">
                    <span class="mr-2">5+ Days:</span>
                    <v-chip x-small class="px-2" :color="!fivePlus ? 'redPop' : 'white'" :style="fivePlus ? {'color' : 'var(--v-redPop-base) !important'} : ''">{{ daysCalculated.fivePlus  }}</v-chip>
                </v-chip>
                <v-chip small :outlined="!threeToFour" color="#dfaa49" @click="switchFilter('threeToFour')" class="px-1 mr-2">
                    <span class="mr-2">3-4 Days:</span>
                    <v-chip x-small class="px-2" :color="!threeToFour ? '#dfaa49' : 'white'" :style="threeToFour ? {'color' : '#dfaa49 !important'} : ''">{{ daysCalculated.threeToFour }}</v-chip>
                </v-chip>
                <v-chip small :outlined="!zeroToTwo" color="tertiary" @click="switchFilter('zeroToTwo')" class="px-1 mr-2">
                    <span class="mr-2">0-2 Days:</span>
                    <v-chip x-small class="px-2" :color="!zeroToTwo ? 'tertiary' : 'white'" :style="zeroToTwo ? {'color' : 'var(--v-tertiary-base) !important'} : ''">{{ daysCalculated.zeroToTwo }}</v-chip>
                </v-chip>
                <v-chip small :outlined="!past" color="grey" @click="switchFilter('past')" class="px-1 mr-2">
                    <span class="mr-2">No ATD/ATA</span>
                    <v-chip x-small class="px-2" :color="!past ? 'grey' : 'white'" style="color: var(--v-component-base) !important;">{{ daysCalculated.past }}</v-chip>
                </v-chip>
                <v-chip small :outlined="!zeroToThree" color="blue" @click="switchFilter('zeroToThree')" class="px-1 mr-2">
                    <span class="mr-2">Arriving</span>
                    <v-chip x-small class="px-2" :color="!zeroToThree ? 'blue' : 'white'" :style="zeroToThree ? {'color' : 'blue'} : ''">{{ daysCalculated.zeroToThree }}</v-chip>
                </v-chip>
            </div>
            <v-spacer></v-spacer>
            <div class="px-0 d-flex justify-center align-center" style="width: 550px;">
                <v-chip class="mr-1 searchChipWrap" :class="{
                    shrinkChipPlus: showBulkUpdate && filterHovered,
                    slightShrink: showBulkUpdate && !filterHovered,
                    shrinkChip: filterHovered && !showBulkUpdate,
                    shrinkMore: chipHovered,
                }">
                    <v-text-field
                    placeholder="Search"
                    class="mb-1 searchInput"
                    hide-details
                    rounded
                    clearable
                    dense
                    style=" margin-top: 2px"
                    v-model="search"
                    ></v-text-field>
                </v-chip>
                <v-chip class="pa-1 mr-1" :class="refreshing ? 'rotateRefresh' : ''" @click="refresh" >
                    <v-icon >refresh</v-icon>
                </v-chip>
                <v-chip class="mr-1 bulkUpdate" color="edit" v-if="showBulkUpdate" :disabled="!canBulkUpdate" @click="bulkUpdateModal = !bulkUpdateModal" @mouseover="chipHovered = true" @mouseleave="chipHovered = false">
                    <v-icon small left>keyboard_double_arrow_up</v-icon>
                    <span class="bulkUpdateText">Bulk Update</span>
                </v-chip>
                <v-chip @click="showCompleted = !showCompleted" :color="showCompleted ? 'tertiary' : ''" class="chipCompleted mr-1" @mouseover="chipHovered = true" @mouseleave="chipHovered = false" v-if="!showBulkUpdate">
                    <v-icon small left>{{ showCompleted ? 'done_all' : 'remove_done'}}</v-icon>
                    <span class="textCompleted">Show Completed</span>
                </v-chip>
                <v-chip color="redPop" :key="updateFilterValue" class="filterHover" @click="resetFilters" v-if="hasFilters" @mouseover="filterHovered = true" @mouseleave="filterHovered = false">
                    <v-icon small left>filter_alt_off</v-icon>
                    <span class="textFilters">Clear Filters</span>
                </v-chip>  
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex justify-end align-center">
                <v-chip class="pr-1 pl-2" :outlined="!rejected" :style="!rejected ? {'border': 'none'} : {'background-color': '#eb403456'}" @click="switchFilter('rejected')">
                    <span class="mr-2" style="font-size: 12px">Rejected:</span>
                    <v-chip small pill color="danger">{{ rejectedCount }}</v-chip>
                </v-chip>
                <v-chip class="pr-1 pl-2" :outlined="!overdue" :style="!overdue ? {'border': 'none'} : {'background-color': '#e371696c'}" @click="switchFilter('overdue')">
                    <span class="mr-2" style="font-size: 12px">Overdue:</span>
                    <v-chip small pill color="redPop">
                        <b>{{ overdueCount }}</b>
                    </v-chip>
                </v-chip>
                <v-chip class="pr-1 pl-2" :outlined="!inDanger" :style="!inDanger ? {'border': 'none'} : {'background-color': '#dfaa494f'}" @click="switchFilter('inDanger')">
                    <span class="mr-2" style="font-size: 12px">In Danger:</span>
                    <v-chip small pill color="#dfaa49">{{ inDangerCount }}</v-chip>
                </v-chip>
                <v-chip class="pr-1 pl-2" :outlined="!withinKPI" :style="!withinKPI ? {'border': 'none'} : {'background-color': '#007d5c52'}" @click="switchFilter('withinKPI')">
                    <span class="mr-2" style="font-size: 12px">Within KPI:</span>
                    <v-chip small pill color="tertiary">{{ withinKPICount }}</v-chip>
                </v-chip>
            </div>
        </div>
        <v-card-text v-if="!$vuetify.breakpoint.mobile" style="max-width: 100%; overflow-x: auto" class="my-0">
            <div :style="{ width: tableWidth }">
                <table :width="'100%'">
                    <tr class="mx-0 px-0">
                        <td class="text-center mx-0 px-0" style="
                                cursor: grab;
                                border-right: 1px solid grey;
                                min-height: 20px;
                                min-width: 20px;
                                " v-for="(header, index) in headers.filter(x => x.show)" :key="index" :width="header.width ?? '150px'">
                            <b style="font-size: 12px" v-if="header.value != 'actions'">{{ header.text }}</b>
                            <v-btn v-if="!header.disableSort" class="ml-1" icon x-small @click="changeSortBy(header.value)">
                                <v-icon v-if="sortBy != header.value" small color="grey">arrow_upward</v-icon>
                                <v-icon v-else-if="sortDesc" small>arrow_downward</v-icon>
                                <v-icon v-else small>arrow_upward</v-icon>
                            </v-btn>
                            <v-badge
                                v-if="!header.disableFilter"
                                :content="
                                    filters[header.value]
                                    ? filters[header.value].length
                                    : false
                                "
                                :value="
                                    filters[header.value] &&
                                    filters[header.value].length > 0
                                "
                                color="primary"
                                offset-y="10"
                                >
                                <v-menu offset-y left :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            color="grey"
                                            icon
                                            ><v-icon small>filter_alt</v-icon></v-btn
                                        >
                                    </template>
                                    <v-card
                                    style="
                                        background: var(--v-modal-base) !important;
                                        border-radius: 20px;
                                    "
                                    >
                                        <v-card-text>
                                            <v-list
                                            dense
                                            style="
                                                background: var(--v-modal-base) !important;
                                                border-radius: 20px;
                                            "
                                            >
                                            <v-text-field
                                                hide-details
                                                rounded
                                                dense
                                                outlined
                                                clearable
                                                prepend-inner-icon="search"
                                                placeholder="Search"
                                                autofocus
                                                v-model="searchHeaders[header.value]"
                                                @input="searchKey++;"
                                            ></v-text-field>
                                            <v-list
                                                dense
                                                style="
                                                max-height: 50vh;
                                                overflow-y: auto;
                                                background: var(--v-modal-base) !important;
                                                "
                                                :key="searchKey"
                                            >
                                                <v-list-item
                                                v-for="(item, index) in headerOptions(
                                                    header.value
                                                )"
                                                :key="index"
                                                >
                                                <v-list-item-action class="pr-0">
                                                    <v-checkbox
                                                    v-model="filters[header.value]"
                                                    :value="item.value"
                                                    hide-details
                                                    @change="calculateFilteredResults()"
                                                    dense
                                                    ></v-checkbox>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                    {{ item.name }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item
                                                v-if="
                                                    headerOptions(header.value).length == 0
                                                "
                                                >
                                                <v-list-item-content class="text-center">
                                                    <span style="font-size: 12px"
                                                    >No results found</span
                                                    >
                                                </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                            </v-list>
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </v-badge>
                            <div style="width: 100%; display: flex; justify-content: center;" v-if="header.value == 'actions' && header.show">
                                <v-checkbox @change="selectAll" v-model="selectAllCheck" style="width: 24px" dense class="pa-0 ma-0" hide-details v-if="header.value === 'actions'"></v-checkbox>
                            </div>
                        </td>
                    </tr>
                </table>
                <v-divider v-if="!loadingFiles"></v-divider>
                <v-progress-linear indeterminate v-if="loadingFiles" style="height: 1px;"></v-progress-linear>
                <div class="mt-1 pt-1 px-1" :style="{ width: '100%' }" v-if="files.length > 0">
                    <v-virtual-scroll v-if="filesMap.length > 0" :bench="10" :items="filesMap" :height="calculatedHeight" :width="'100%'" item-height="35" class="px-1">
                        <template v-slot:default="{ item }">
                            <div class="text-center my-0 py-0" :style="{
                                    display: 'flex',
                                    'flex-direction': 'row',
                                    width: '100%',
                                    'background-color': 'transparent',
                                    cursor: 'pointer',
                                }">
                                <td v-for="(header, index) in headers.filter(x => x.show)" :key="index" :style="{
                                    width: header.width ?? '150px',
                                    'font-size': '11px',
                                    }" class="text-center mt-0 mb-0 pb-0">
                                    <div v-if="header.value == 'documentationStatus'" class="text-center">
                                        <v-chip small  outlined>
                                            {{ item.status }}</v-chip>
                                    </div>
                                    <div v-else-if="header.value == 'responsiblePartyType'">
                                        <v-tooltip top>
                                            <template v-slot:activator="{on}">
                                                <v-chip v-if="item.responsiblePartyType" color="white" v-on="on" small outlined class="px-1 primaryText--text" @click="addToFilter(item[header.value], header.value)">
                                                    <v-icon small v-if="item.responsiblePartyType == 'forwarder'">fast_forward</v-icon>
                                                    <v-icon small v-if="item.responsiblePartyType == 'shipper'">directions_boat</v-icon>
                                                    <v-icon small v-if="item.responsiblePartyType == 'buyer'">shopping_cart</v-icon>
                                                    <v-icon small v-if="item.responsiblePartyType == 'consignee'">call_received</v-icon>
                                                    <v-icon small v-if="item.responsiblePartyType == 'notify party'">notifications</v-icon>
                                                </v-chip>
                                            </template>
                                            <span>{{item.responsiblePartyType ? item.responsiblePartyType.charAt(0).toUpperCase() + item.responsiblePartyType.slice(1) : ''  }}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'document'">
                                        <div class="d-flex">
                                            <v-btn icon small plain @click="viewDocument(item)" :loading="documentLoading == item.id">
                                                    <v-icon small>launch</v-icon>
                                                </v-btn>
                                            <v-chip class="primaryText--text" small outlined style="border:none" @click="addToFilter(item[header.value], header.value)">
                                                <span :class="item.kpiCategory == 'OVERDUE' ? 'overdue' : item.kpiCategory == 'DANGER' ? 'inDanger' :item.kpiCategory == 'GOOD' ? 'withinKPI' : ''"
                                                >{{ item.document }}</span>
                                                <!-- <v-icon right small v-if="item.kpiCategory"
                                                :color="item.kpiCategory == 'OVERDUE' ? 'error' : item.kpiCategory == 'DANGER' ? 'warning' : 'success'"
                                                >
                                                {{ item.kpiCategory == 'OVERDUE' ? 'error' : item.kpiCategory == 'DANGER' ? 'warning' : 'check_circle' }}
                                                </v-icon> -->
                                            </v-chip>
                                        </div>
                                    </div>
                                    <div v-else-if="header.value == 'fileNumber'" class="text-center">
                                        <v-btn small plain icon @click="viewShipment(item)"><v-icon small>launch</v-icon></v-btn>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-on="on" small class="primaryText--text" outlined style="border: none" @click="addToFilter(item[header.value], header.value)">
                                                    <v-avatar class="smallerAvatar" left 
                                                    :color="item.daysFromToday >= 5 ? 'redPop' : 
                                                    (item.daysFromToday >= 3) && (5 > item.daysFromToday) ? '#dfaa49' : 
                                                    (item.daysFromToday >= 0) && (3 > item.daysFromToday) ? 'tertiary' :
                                                    0 > item.daysFromToday ? 'grey' : '' 
                                                    "
                                                    >
                                                        <b style="color: white; font-size: 10px">
                                                            {{ 0 > item.daysFromToday ? '-' : item.daysFromToday }}
                                                        </b>
                                                    </v-avatar>
                                                    {{ item.fileNumber }}
                                                </v-chip>
                                            </template>
                                            <span>{{
                                            $Format.capitalizeFirstLetter(
                                            item.movementType
                                            )
                                        }}
                                                Shipment</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'vesselVoyage'" class="text-left">
                                        <v-chip v-if="item.vesselVoyage" @click="addToFilter(item[header.value], header.value)" small class="primaryText--text" outlined style="border: none; font-size: 11px"><span>{{ item.vesselVoyage }}</span>
                                        </v-chip>
                                    </div>

                                    <div v-else-if="header.value == 'portOfLoadValue'" class="text-center">
                                        <v-tooltip top v-if="item.portOfLoadValue">
                                            <template v-slot:activator="{ on }">
                                                <v-chip outlined style="border: none; font-size: 11px" v-on="on" small @click="addToFilter(item[header.value], header.value)">
                                                    <v-avatar size="20" left>
                                                        <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                    </v-avatar>
                                                    {{ item.portOfLoadValue }}
                                                </v-chip>
                                            </template>
                                            <span style="font-size: 12px">{{
                                        item.portOfLoadCity
                                        }}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'finalDestinationValue'" class="text-center">
                                        <v-tooltip top v-if="item.finalDestinationValue">
                                            <template v-slot:activator="{ on }">
                                                <v-chip outlined style="border: none; font-size: 11px" v-on="on" small @click="addToFilter(item[header.value], header.value)">
                                                    <v-avatar size="20" left>
                                                        <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                    </v-avatar>
                                                    {{ item.finalDestinationValue }}
                                                </v-chip>
                                            </template>
                                            <span style="font-size: 12px">{{item.finalDestinationCity}}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'consigneeName'" class="text-left">
                                        <v-tooltip top v-if="item.consigneeName">
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-if="item.consigneeName" small class="primaryText--text" outlined v-on="on" style="border: none" @click="addToFilter(item[header.value], header.value)">
                                                    <v-avatar size="14" class="smallerAvatar" :color="item.consignee.logo ? 'white' : 'secondary'" left>
                                                        <v-img style="" v-if="item.consignee.logo" :src="item.consignee.logo" contain></v-img>
                                                        <h4 v-else style="color: white">
                                                            {{ item.consigneeName.charAt(0) }}
                                                        </h4>
                                                    </v-avatar>
                                                    {{ $Format.removePtyLtd(item.consigneeName) ?? ''}}
                                                </v-chip>
                                            </template>
                                            <span>{{ item.consigneeName ?? ''}}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'buyerName'" class="text-left">
                                        <v-tooltip top v-if="item.buyerName">
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-if="item.buyerName" small class="primaryText--text" v-on="on" outlined @click="addToFilter(item[header.value], header.value)" style="border: none">
                                                    <v-avatar size="14" class="smallerAvatar" :color="item.buyer && item.buyer.logo ? 'white' : 'secondary'" left>
                                                        <v-img v-if="item.buyer.logo" :src="item.buyer.logo" contain></v-img>
                                                        <h4 v-else style="color: white">
                                                            {{ item.buyerName ? item.buyerName.charAt(0) : ''}}
                                                        </h4>
                                                    </v-avatar>
                                                    {{ item.buyerName ? $Format.removePtyLtd(item.buyerName) : '' }}
                                                </v-chip>
                                            </template>
                                            <span>{{ item.buyerName }}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'customerName'" class="text-left">
                                        <v-tooltip top v-if="item.customerName">
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-if="item.customerName" small class="primaryText--text" v-on="on" outlined style="border: none" @click="addToFilter(item[header.value], header.value)">
                                                    <v-avatar size="14" class="smallerAvatar" :color="item.customer && item.customer.logo ? 'white' : 'secondary'" left>
                                                        <v-img v-if="item.customer.logo" :src="item.customer.logo" contain></v-img>
                                                        <h4 v-else style="color: white">
                                                            {{ item.customerName.charAt(0) }}
                                                        </h4>
                                                    </v-avatar>
                                                    {{ $Format.removePtyLtd(item.customerName) }}
                                                </v-chip>
                                            </template>
                                            <span>{{ item.customerName }}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'shipperName'" class="text-left">
                                        <v-tooltip top v-if="item.shipperName">
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-if="item.shipperName" small class="primaryText--text" v-on="on" outlined style="border: none" @click="addToFilter(item[header.value], header.value)">
                                                    <v-avatar size="14" class="smallerAvatar" :color="item.shipper && item.shipper.logo ? 'white' : 'secondary'" left>
                                                        <v-img v-if="item.shipper.logo" :src="item.shipper.logo" contain></v-img>
                                                        <h4 v-else style="color: white">
                                                            {{ item.shipperName.charAt(0) }}
                                                        </h4>
                                                    </v-avatar>
                                                    {{ $Format.removePtyLtd(item.shipperName) }}
                                                </v-chip>
                                            </template>
                                            <span>{{ item.shipperName }}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'etd'">
                                        <v-chip v-if="item.etd" small class="primaryText--text" outlined style="border: none" @click="addToFilter(item[header.value], header.value)">
                                            {{ $Format.formatDate(item.etd).isoFormat }}
                                        </v-chip>
                                    </div>
                                    <div v-else-if="header.value == 'eta'">
                                        <v-chip v-if="item.eta" small class="primaryText--text" outlined style="border: none" @click="addToFilter(item[header.value], header.value)">
                                            {{ $Format.formatDate(item.eta).isoFormat }}
                                        </v-chip>
                                    </div>
                                    <div v-else-if="header.value == 'submittedDate'">
                                        <v-tooltip top color="success" v-if="item.submittedDate">
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-on="on" class="primaryText--text"  small outlined style="border: none">
                                                {{ item.submittedDate }}
                                            </v-chip>
                                            </template>
                                            <span>Date of Submission</span>
                                        </v-tooltip>
                                        <v-tooltip top v-if="!item.submittedDate">
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-on="on" class="primaryText--text"  small outlined style="border: none; color:#5f5f5f !important">
                                                    {{ item.submittedDue }}
                                                </v-chip>
                                            </template>
                                            <span>Submission Due Date</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'completedDate'">
                                        <v-tooltip top color="success" v-if="item.completedDate">
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-on="on" class="primaryText--text" small  outlined style="border: none">
                                                    {{ item.completedDate }}
                                                </v-chip>
                                            </template>
                                            <span>Date of Completion</span>
                                        </v-tooltip>
                                        <v-tooltip top v-if="!item.completedDate">
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-on="on" class="primaryText--text" small  outlined style="border: none; color:#5f5f5f !important">
                                                    {{ item.completedDue }}
                                                </v-chip>
                                            </template>
                                            <span>Completion Due Date</span>
                                        </v-tooltip>
                                    </div>



                                    <div v-else-if="header.value == 'status'" class="text-left">
                                        <v-menu right offset-x :close-on-content-click="false">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn :color="chatColor(item.comments)" v-on="on" class="mb-0 pb-0" v-bind="attrs" small icon @click="getShipmentDocumentLog(item.id)">
                                                    <v-icon small>forum</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-card width="20vw" style="overflow-y: hidden">
                                                <v-subheader>{{ item.document }}</v-subheader>
                                                <v-divider v-if="!loadingComments"></v-divider>
                                                <v-progress-linear indeterminate style="height: 1px;" v-if="loadingComments"></v-progress-linear>
                                                <v-card height="40vh" width="inherit" style="overflow-y: auto" flat>
                                                    <v-list>
                                                        <!-- No Comments -->
                                                        <v-list-item v-if="!loadingComments && (!item.comments || item.comments.length === 0)">
                                                            <v-list-item-content class="text-center">
                                                                <span style="font-size: 12px; color: grey">No comments.</span>
                                                            </v-list-item-content>
                                                        </v-list-item>

                                                        <!-- Display Comments -->
                                                        <v-list-item v-else v-for="(comment, index) in item.comments" :key="index" :id="'comment_' + index" class="py-0 my-1">
                                                            <!-- Other Users' Comments -->
                                                            <v-list-item-avatar v-if="comment.createdBy && comment.createdBy.id !== $store.state.user.id">
                                                                <v-tooltip top v-if="comment.createdBy">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer" color="secondary" size="48" class="mt-0 pt-0">
                                                                            <span v-if="comment.createdBy && !comment.createdBy.avatar" class="primaryText--text text-h5">
                                                                                {{$Format.avatarAbbreviation(comment.createdBy?.firstname + ' ' + comment.createdBy?.surname)}}
                                                                            </span>
                                                                            <img v-else-if="comment.createdBy?.avatar" :src="comment.createdBy?.avatar" referrerpolicy="no-referrer" />
                                                                        </v-avatar>
                                                                    </template>
                                                                    <span style="font-size: 12px">{{ comment.createdBy?.firstname }} {{ comment.createdBy?.surname }}</span>
                                                                </v-tooltip>
                                                            </v-list-item-avatar>


                                                            <!-- Current User's Comments -->
                                                            <v-list-item-content :class="comment.createdBy && comment.createdBy.id === $store.state.user.id ? 'text-right' : 'text-left'">
                                                                    <v-list-item-title class="text-wrap">
                                                                        {{ comment.comment }}
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle>
                                                                        <i style="font-size: 12px; color: grey">
                                                                            {{ comment.commentType }}
                                                                        </i>
                                                                    </v-list-item-subtitle>
                                                                    <v-list-item-subtitle>
                                                                        <i style="font-size: 12px; color: grey">{{$Format.formatDate(comment.createdAt).dateTime}}</i>
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>


                                                            <!-- Current User's Avatar on Right -->
                                                            <v-list-item-avatar v-if="comment.createdBy && comment.createdBy.id === $store.state.user.id">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer" color="secondary" size="48" class="mt-0 pt-0">
                                                                            <span v-if="!comment.createdBy.avatar" class="primaryText--text text-h5">
                                                                                {{$Format.avatarAbbreviation(comment.createdBy.firstname + ' ' + comment.createdBy.surname)}}
                                                                            </span>
                                                                            <img v-else :src="comment.createdBy.avatar" referrerpolicy="no-referrer" />
                                                                        </v-avatar>
                                                                    </template>
                                                                    <span style="font-size: 12px">{{ comment.createdBy.firstname }} {{ comment.createdBy.surname }}</span>
                                                                </v-tooltip>
                                                            </v-list-item-avatar>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card>
                                                <v-card flat width="inherit" style="background-color: var(--v-greyRaised-base) !important; overflow-y: hidden;">
                                                    <div style="display: flex; justify-content: center; align-content: center;" class="mb-2">
                                                        <v-chip small outlined class="mx-3" @click="commentItem.commentType = 'General'" :color="commentItem.commentType == 'General' ? 'tertiary' : ''">General</v-chip>
                                                        <v-chip small outlined class="mx-3" @click="commentItem.commentType = 'Invoice'" :color="commentItem.commentType == 'Invoice' ? 'tertiary' : ''">Invoice</v-chip>
                                                        <v-chip small outlined class="mx-3" @click="commentItem.commentType = 'Query'" :color="commentItem.commentType == 'Query' ? 'tertiary' : ''">Query</v-chip>
                                                    </div>
                                                    <div style="display: flex;align-items: center;">
                                                        <v-textarea class="ml-2" no-resize hide-details outlined autofocus height="7vh"
                                                            style="width: 80%; contain: content" placeholder="Send a message"
                                                            v-model="commentItem.comment" :value="commentItem.comment">
                                                        </v-textarea>
                                                        <v-btn :loading="savingComment" icon class="ml-3 mr-2" @click="commentDoc(item)">
                                                            <v-icon>send</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </v-card>
                                            </v-card>
                                        </v-menu>
                                        <v-chip v-if="item[header.value]" small class="primaryText--text" outlined style="border: none" @click="addToFilter(item[header.value], header.value)">
                                            <span :class="item.status == 'Rejected' ? 'rejected' : ''">{{ item[header.value] }}</span>
                                        </v-chip>
                                    </div>




                                    <div v-else-if="header.value == 'actions' && header.show">
                                        <div style="width: 100%; display: flex; justify-content: center;" class="pr-1" v-if="header.value == 'actions' && header.show">
                                            <v-checkbox 
                                                v-if="header.value === 'actions'"
                                                style="width: 24px" 
                                                class="pa-0 ma-0" 
                                                hide-details 
                                                dense 
                                                v-model="item.selected"
                                                @change="checkAllSelected"
                                                :key="selectUpdateKey + item.id"
                                                ></v-checkbox>
                                        </div>
                                    </div>

                                    <div v-else>
                                        <v-chip v-if="item[header.value]" small class="primaryText--text" outlined style="border: none" @click="addToFilter(item[header.value], header.value)">
                                            {{ item[header.value] }}
                                        </v-chip>
                                    </div>
                                </td>
                            </div>
                            <v-divider class="my-0 py-0"></v-divider>
                        </template>
                    </v-virtual-scroll>
                </div>
            </div>
            <div class="container" v-if="!loading && files.length == 0">
                <h3 style="color: grey">No Results Found</h3>
            </div>
            <div class="container" v-else-if="loading && files.length == 0">
                <valhalla-loading height="500px" />
            </div>
        </v-card-text>
    </v-card-text>
    <v-dialog
        v-model="viewOpenFiles"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
    <v-card>
        <OpenFiles :viewOpenFiles="viewOpenFiles" @filterTable="filterFromOpenFiles" :selectedUser="selectedUser" :isDialog="true" @close="clearOpenFiles" :filesParent="openFiles"/>
    </v-card>

    </v-dialog>
    <v-dialog
        v-model="viewUnprocessed"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
    <v-card>
        <ReadyFiles :selectedUser="selectedUser" :isDialog="true" :filterKey="readyFilesKey" @close="viewUnprocessed = false" @refresh="getCounts()"/>
    </v-card>
    </v-dialog>
    <v-dialog
        v-model="viewNotReady"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
    <v-card>
        <NotReadyToInvoice :selectedUser="selectedUser" :isDialog="true" @close="viewNotReady = false" :key="userRefreshKey"/>
    </v-card>
    </v-dialog>
    <v-dialog persistent v-model="documentModal" fullscreen>
            <DocumentModal @close="documentModal = false" :shipment="shipment" :item="shipment" :actions="actions"
                :bookings="bookings" :documentItem="document" :tasks="documentTasks" :documents="documentTypes"
                :documentTypes="documentTypes" :fileNumber="fileNumber" :uploads="documentUploads"
                :openKey="openKey" @updateDocument='updateDocument'/>
    </v-dialog>
    <v-dialog v-model="bulkUpdateModal" width="auto">
        <v-card v-if="bulkUpdateType && canBulkUpdate">
            <v-toolbar flat color="transparent">
                <v-toolbar-title>
                    {{ bulkUpdateType == 'Pending' ? 'Update Submitted Date' :
                        'Update Completed Date' }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon small @click="bulkUpdateModal = false" color="redPop">
                <v-icon small>close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <!--Date picker-->
                <v-date-picker v-model="bulkDate" scrollable style="margin: 5px;">
                    <v-row justify="center" style="margin-top: 4px;">
                        <v-btn :disabled="!bulkDate" text color="primary" :loading="bulkUpdateLoading" @click="bulkUpdateDates">
                            Submit
                        </v-btn>
                    </v-row>
                </v-date-picker>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="userAgreement" width="20vw">
        <v-card>
            <v-card-title>Disclaimer <v-spacer></v-spacer><v-btn icon @click="userAgreement = false"
                    color="primary"><v-icon>close</v-icon></v-btn></v-card-title>
            <v-card-text><p><b><u>Please take note that you are signing away your life</u></b> and potentially making someone else's day!</p>
                <p>By signing this you are saying that we as a company can deduct an amount from your salary and pay to a random
                someone else if something went wrong with this shipment.</p></v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="userAgreement = false, agreed = true">
                    I agree
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import DocumentModal from "@/components/SystemComponents/DocumentModal.vue";
import ReadyFiles from "./ReadyFiles.vue";
import NotReadyToInvoice from "./NotReadyToInvoice.vue";
import OpenFiles from "./OpenFiles.vue";
import dateFormat from 'dateformat'

export default {
    components: {
        DocumentModal,
        ReadyFiles,
        NotReadyToInvoice,
        OpenFiles
    },
    data: () => ({
        docUsers: [],
        searchUsers: "",
        bulkUpdateType: "",
        selectedUser: null,
        fivePlus: false,
        zeroToThree:false,
        bulkDate: null,
        viewOpenFiles: false,
        userAgreement: false,
        agreed: false,
        bulkUpdateModal: false,
        bulkUpdateLoading: false,
        savingComment: false,
        searchHeaders: {},
        updateOnSelectedKey: 51,
        viewUnprocessed: false,
        chipHovered: false,
        filterHovered: false,
        selectUpdateKey: 0,
        readyFilesKey: 25,
        canBulkUpdate: false,
        showBulkUpdate: false,
        fileRequest: undefined,
        completedFileTimeout: undefined,
        completedFileRequest: undefined,
        refreshing: false,
        fileTimeout: undefined,
        countsTimeout: undefined,
        showSelect: false,
        countsRequest: undefined,
        loadingCounts: false,
        loadingComments: false,
        viewNotReady: false,
        threeToFour: false,
        selectAllCheck: false,
        filteredResults: [],
        showCompleted: false,
        statusFilter: ["OPEN"],
        filteredValues: [],
        bulkUpdateArray: [],
        filters: {},
        zeroToTwo: false,
        past: false,
        openFilesFilter: '',
        rejected: '',
        overdue: '',
        inDanger: '',
        withinKPI: '',
        commentItem: {
            comment: '',
            commentType: 'Query'
        },
        shipmentCounts: null,
        searchKey: 0,
        loadingCompletedFiles: false, 
        storedFiles: [], 
        documentLoading: null,
        document: {},
        documentTypes: [],
        openFiles: [],
        documentTasks: [],
        documentUploads: [],
        actions: [],
        shipment: {},
        bookings: [],
        pinnedUsers: [],
        fileNumber: '',
        openKey: 5000,
        documentModal: false,
        loadingFiles: false,
        readyFiles: {count: 0},
        updateFilterValue: 0,
        search: "",
        sortDesc: null,
        sortBy: null,
        loading: false,
        files: [],
        notReadyInvoice: {
            data: [],
            count: 0
        },
        headers: [
            {
                text: '',
                value: 'actions',
                align: 'center',
                width: '50px',
                disableSort: true,
                disableFilter: true,
                show: false,
            },
            {
                text: "Customer",
                value: "customerName",
                align: 'center',
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Shipper",
                value: "shipperName",
                align: 'center',
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Consignee",
                value: "consigneeName",
                align: 'center',
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "File No.",
                value: "fileNumber",
                align: 'center',
                width: "180px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Vessel & Voyage",
                value: "vesselVoyage",
                align: 'center',
                width: "200px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Party",
                value: "responsiblePartyType",
                align: 'center',
                width: "90px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Document",
                value: "document",
                align: 'center',
                width: "220px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Status",
                value: "status",
                align: 'left',
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Reference",
                value: "reference",
                align: 'center',
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Submitted",
                value: "submittedDate",
                align: 'center',
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Re-Submitted",
                value: "resubmitDate",
                align: 'center',
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Completed",
                value: "completedDate",
                align: 'center',
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "POL",
                value: "portOfLoadValue",
                align: 'center',
                width: "120px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "FD",
                value: "finalDestinationValue",
                align: 'center',
                width: "120px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "ETD",
                value: "etd",
                align: 'center',
                width: "120px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "ETA",
                value: "eta",
                align: 'center',
                width: "120px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
        ],
        userModal: false,
        userRefreshKey: 300
    }),
    async created() {
        this.headers.forEach(header => {
        this.filters[header.value] = [];
        });
        if(sessionStorage.getItem('selectedUser')) {
            this.selectedUser = JSON.parse(sessionStorage.getItem('selectedUser'))
        } else {
            this.selectedUser = {
                id: this.$store.state.user.id,
                fullName: this.$store.state.user.firstname + " " + this.$store.state.user.surname,
                emailAddress: this.$store.state.user.email,
                avatar: this.$store.state.user.avatar
            }
        }
        this.getDocUsers();
        if(sessionStorage.getItem('doc_filters')) {
            let res = JSON.parse(sessionStorage.getItem('doc_filters'));
            if(res && typeof res == 'object' && Object.values(res).length > 0) {
                this.filters = res;
                this.checkConditions();
            } else {
                this.resetFilters();
            }
        } else {
            this.resetFilters();
        }
        if(sessionStorage.getItem('files')) {
            this.files = JSON.parse(sessionStorage.getItem('files'));
            this.calculateFilteredResults();
            await this.getDocs();
        } else {
            await this.getDocs();
            sessionStorage.setItem('files', JSON.stringify(this.files))
        }
        this.getCounts()
        this.getCompletedForStash()
    },
    watch: {
        'showCompleted': {
            immediate: true,
            handler(val) {
                    this.getDocs();
            },
        },
        'filters.status': function(newVal, oldVal) {
        this.checkConditions();
        },
        'filters.document': function(newVal, oldVal) {
            this.checkConditions();
        },
        'bulkUpdateModal': function(newVal, oldVal) {
            if (newVal == false) {
                this.bulkUpdateArray = [];
            } else {
                this.bulkUpdateArray = this.files
                    .filter(x => x.selected)
                    .map(x => {
                        return {
                            id: x.id,
                            shipmentId: x.shipmentId,
                        }
                    });
            }
        },
    },
    computed: {
        openFilesCount() {
            let result = 0
            let uniqueShipments;
            if(this.storedFiles) {
                uniqueShipments = this.storedFiles.reduce((acc, file) => {
                    let fileNumber = file.shipment.shipmentFile.fileNumber;
                    if (!acc[fileNumber]) {
                        acc[fileNumber] = file.shipment;
                    }
                    return acc;
                }, {});
            }
            if(Object.values(uniqueShipments).length > 0) {
                result = Object.values(uniqueShipments).length;
            }
            return result
        },
        daysCalculated() {
            let result = {
                fivePlus: 0,
                threeToFour: 0,
                zeroToTwo: 0,
                past: 0,
                zeroToThree: 0
            }
            if(this.filesMap.length > 0) {
                const uniqueShipments = this.filesMap.reduce((unique, item) => {
                    return unique.find(x => x.fileNumber === item.fileNumber) ? unique : [...unique, { fileNumber: item.fileNumber, daysFromToday: item.daysFromToday }];
                }, []);
                console.log(uniqueShipments)
                for(let i = 0; i < uniqueShipments.length; i++) {
                 let days = uniqueShipments[i].daysFromToday;

                    if (days < 0) {
                        result.past++;
                    }
                    else if (days >= 5) {
                        result.fivePlus++;
                    }
                    else if (days >= 3 && days < 5) {
                        result.threeToFour++;
                    }
                    else if (days >= 0 && days <= 3) {
                        result.zeroToThree++; 
                        if (days >= 0 && days <= 2) {
                            result.zeroToTwo++;
                        }
                    }
                }
            }
            return result
        },
        rejectedCount() {
            if(this.filesMap.length > 0) {
                return this.filesMap.filter(x => x.status == 'Rejected').length;
            } else {
                return 0;
            }
        },
        overdueCount() {
            if(this.filesMap.length > 0) {
                return this.filesMap.filter(x => x.kpiCategory == 'OVERDUE').length;
            } else {
                return 0;
            }
        },
        inDangerCount() {
            if(this.filesMap.length > 0) {
                return this.filesMap.filter(x => x.kpiCategory == 'DANGER').length;
            } else {
                return 0;
            }
        },
        withinKPICount() {
            if(this.filesMap.length > 0) {
                return this.filesMap.filter(x => x.kpiCategory == 'GOOD').length;
            } else {
                return 0;
            }
        },

        pinUsersDisabled() {
            return this.pinnedUsers.length == 3;
        },
        hasFilters() {
            let result = false;
            let vals = Object.values(this.filters);
            if (vals.some(x => x.length > 0)) {
                return true;
            }
            ['fivePlus','threeToFour','zeroToTwo','past','zeroToThree','openFilesFilter','rejected','overdue','inDanger','withinKPI',].some(x => {
                if (this[x]) {
                    result = true;
                }
            });
            return result
        },
        calculatedHeight() {
            let height = window.innerHeight;
            return height * 0.78 + "px";
        },
        tableWidth() {
            let result = 0;
            this.headers.filter(x => x.show).forEach((x) => {
                result += parseInt(x.width ? x.width.replace("px", "") : 150);
            });
            return result + "px";
        },
        docsUsersFiltered() {
            let users = []
            if (this.docUsers.length > 0) {
                if (this.searchUsers) {
                    users = this.docUsers.filter(user => user.fullName.toLowerCase().includes(this.searchUsers.toLowerCase()));
                } else {
                    users = this.docUsers;
                }
            }
            return users;
        },
        filesMap() {
            return this.filteredResults.filter(x => {
                let daysFilter = true;  
                if (this.past) {
                    daysFilter = x.daysFromToday < 0;
                }
                else if (this.fivePlus) {
                    daysFilter = x.daysFromToday >= 5;
                }
                else if (this.threeToFour) {
                    daysFilter = x.daysFromToday >= 3 && x.daysFromToday < 5;
                }
                else if (this.zeroToThree) {
                    daysFilter = x.daysFromToday >= 0 && x.daysFromToday <= 3;
                }
                else if (this.zeroToTwo) {
                    daysFilter = x.daysFromToday >= 0 && x.daysFromToday <= 2;
                }
               
                 
                
                let statusFilter = true;
                if (this.openFilesFilter) {
                    statusFilter = x.documentationStatus === 'OPEN';
                } else if (this.rejected) {
                    statusFilter = x.status === 'Rejected';
                } else if (this.overdue) {
                    statusFilter = x.kpiCategory === 'OVERDUE';
                } else if (this.inDanger) {
                    statusFilter = x.kpiCategory === 'DANGER';
                } else if (this.withinKPI) {
                    statusFilter = x.kpiCategory === 'GOOD';
                }
            
                let searchFilter = true;
                if (this.search) {
                    searchFilter = [ x.fileNumber, x.reference, x.etd, x.eta, x.responsiblePartyType,
                        x.customerName, x.buyerName, x.shipperName, x.consigneeName, x.movementType,
                        x.vesselVoyage, x.document, x.portOfLoadValue, x.portOfLoadCity,
                        x.finalDestinationValue, x.finalDestinationCity, x.status,
                        x.submittedDate, x.resubmitDate, x.completedDate
                    ].some(field => field && field.toLowerCase().includes(this.search.toLowerCase()));
                }
            
                return daysFilter && statusFilter && searchFilter;
            }).sort((a, b) => {
                if (this.sortBy) {
                    return this.sortDesc ? (a[this.sortBy] < b[this.sortBy] ? 1 : -1) : (a[this.sortBy] > b[this.sortBy] ? 1 : -1);
                }
                return 0;
            });
        },
    },
    methods: {

        updateDocument() {
            this.getDocs()
            this.documentModal = false
        },

        openFilesActive() {
            this.loadingFiles = true
            this.openFiles = []
            let files = this.storedFiles
            files = JSON.parse(JSON.stringify(files));
            let uniqueShipments = files.reduce((acc, file) => {
                let fileNumber = file.shipment.shipmentFile.fileNumber;
                if (!acc[fileNumber]) {
                    acc[fileNumber] = file.shipment;
                }
                return acc;
            }, {});

            if(Object.values(uniqueShipments).length > 0) {
                this.openFiles = Object.values(uniqueShipments);
            }
            for(let i = 0; i < this.openFiles.length; i++) {
                let rejectedCount = 0;
                let overdueCount = 0;
                let inDangerCount = 0;
                let withinKPICount = 0;
                let relatedFiles = files.filter(x => x.shipmentId == this.openFiles[i].id);
                for(let j = 0; j < relatedFiles.length; j++) {
                    if(relatedFiles[j].status == 'Rejected') {
                        rejectedCount++;
                    }
                    if(relatedFiles[j].kpiCategory == 'OVERDUE') {
                        overdueCount++;
                    }
                    if(relatedFiles[j].kpiCategory == 'DANGER') {
                        inDangerCount++;
                    }
                    if(relatedFiles[j].kpiCategory == 'GOOD') {
                        withinKPICount++;
                    }
                    if(relatedFiles[j].customerName) {
                        this.openFiles[i].customerName = relatedFiles[j].customerName;
                        this.openFiles[i].customer = relatedFiles[j].customer;
                    }
                }
                this.openFiles[i].rejectedCount = rejectedCount;
                this.openFiles[i].overdueCount = overdueCount;
                this.openFiles[i].inDangerCount = inDangerCount;
                this.openFiles[i].withinKPICount = withinKPICount;
                this.openFiles[i].fileNumber = this.openFiles[i].shipmentFile.fileNumber;
                this.openFiles[i].shipperName = this.openFiles[i].shipper?.name;
                this.openFiles[i].consigneeName = this.openFiles[i].consignee?.name;
                this.openFiles[i].vesselVoyage = this.openFiles[i].vessel?.name + " " + this.openFiles[i].voyage;
            }
            this.openFiles = JSON.parse(JSON.stringify(this.openFiles));
            console.log(this.openFiles)
            this.loadingFiles = false
            this.viewOpenFiles = true
        },

        clearOpenFiles() {
            this.openFiles = []
            this.viewOpenFiles = false
        },

        chatColor(comments) {
            let result = ''
            if(comments.length > 0) {
                if(Object.keys(comments[0]).includes('shipmentDocumentTypeComment')) {
                    //check if all the values for shipmentDocumentTypeComment are null. If all are null, then the comments are not related to the document
                    let check = []
                    comments.forEach(x => {
                        if(x.shipmentDocumentTypeComment && x.shipmentDocumentTypeComment.comment) {
                            check.push(x.shipmentDocumentTypeComment.comment)
                        }
                    if(check.length > 0) {
                        result = 'tertiary'
                    } else {
                        result = ''
                    }
                    })
                } else {
                    result = 'tertiary';
                }
            } else {
                result = ''
            }
            return result
        },

        refresh() {
            this.getDocs();
            this.loadingFiles = true;
            this.getCounts()
            this.loadingCounts = true;
            this.refreshing = true;
            this.getDocUsers();
            setTimeout(() => {
                this.refreshing = false;
            }, 500);
        },
        pinUser() {
            if(this.pinnedUsers.some(x => x.id == this.selectedUser.id)) {
                    this.pinnedUsers = this.pinnedUsers.filter(x => x.id != this.selectedUser.id);
            } else if(this.pinnedUsers.length < 3) {
                    this.pinnedUsers.push(this.selectedUser);
            }
            sessionStorage.setItem('pinnedUsers', JSON.stringify(this.pinnedUsers));
        },
        async resetFilters() {
            let keys = Object.keys(this.filters);
            keys.forEach((x) => {
                this.filters[x] = [];
                this.searchHeaders[x] = null;
            });
            let result = [];
            keys.forEach((x) => {
            result = [
                ...result,
                ...this.filters[x].map((y) => ({
                field: x,
                fieldName: this.headers.find((z) => z.value == x).text,
                value: y,
                })),
            ];
            });
            this.filteredValues = result;
            ['fivePlus','threeToFour','zeroToTwo','past','zeroToThree','openFilesFilter','rejected','overdue','inDanger','withinKPI'].forEach(x => {
                this[x] = false;
            });
            this.headers.find(x => x.value == 'actions').show = false;
            await this.unselectAll();
            this.updateFilterValue++;           
            this.calculateFilteredResults();
        },
        async bulkUpdateDates() {
            let response = 0
            console.log(this.selectAllCheck)
            if (this.selectAllCheck) {
                let result = await this.agreements()
                console.log('result',result)
                response = result
            }
            else {
                response = 200
            }
            console.log('response', response);
            if (response == 200 && this.agreed == false) {
                let obj = {}
                this.bulkUpdateLoading = true
                if (this.bulkUpdateArray.length > 0 && this.bulkUpdateType == 'Pending') {
                    obj = {
                        submittedDate: this.bulkDate,
                        shipmentDocumentIds: this.bulkUpdateArray.map(x => x.id),
                        shipmentId: this.bulkUpdateArray.map(x => x.shipmentId),
                        type: 'Pending'
                    }
                }
                else {
                    obj = {
                        completedDate: this.bulkDate,
                        shipmentDocumentIds: this.bulkUpdateArray.map(x => x.id),
                        shipmentId: this.bulkUpdateArray.map(x => x.shipmentId),
                        type: 'Submitted'
                    }
                }
                console.log(obj)
                try {
                    let res = await this.$API.bulkUpdateShipmentDocuments(obj)
                    this.$message.success('Successfully updated!')
                    this.bulkUpdateModal = false
                    this.bulkUpdateLoading = false
                    this.refresh()
                    this.bulkUpdateArray = []
                    this.unselectAll()
                }
                catch (e) {
                    this.$message.error('An error ocurred')
                    this.bulkUpdateArray = []
                    this.bulkUpdateLoading = false
                    this.unselectAll()
                    console.log(e)
                }
            }
        },
        async filterFromOpenFiles(item, field) {
            if(item && field) {
                await this.resetFilters();
                await this.addToFilter(item.fileNumber, 'fileNumber');
                this.switchFilter(field);
                this.viewOpenFiles = false;
                console.log(item, field)
            }
        },

        async agreements() {
            // I, Zenon, copied this section of code, so whatever the comments are below, I don't know what they are for
            return new Promise((resolve, reject) => {
                if (this.selectAll) {
                    this.userAgreement = true;
                    const unwatch = this.$watch('agreed', (newVal) => {
                        // Cleanup the watcher after it triggers
                        unwatch();
                        if (newVal === true) {
                            // Reset for future use
                            this.agreed = false;
                            resolve(200);
                        } else if (newVal === false) {
                            // Handle disagree case if needed
                            reject('User disagreed');
                        }
                    });
                } else {
                    resolve(200);
                }
            });
        },
        checkConditions(value, field) {
            if(value && field) {
                    if(((value == 'Pending' || value == 'Submitted') && field == 'status') && this.filters['document'].length == 1 && this.filters['status'].length == 1) {
                        if(this.filters['status'].includes(value)) {
                            this.headers.find(x => x.value == 'actions').show = false;
                            this.bulkUpdateType =   '';
                            this.showBulkUpdate = false;
                            this.unselectAll();
                        } else {
                        this.headers.find(x => x.value == 'actions').show = true;
                        this.showBulkUpdate = true;
                        this.bulkUpdateType = value;                        
                    }
                }
            } else {
                if((this.filters['status'].includes('Pending') || this.filters['status'].includes('Submitted')) && this.filters['document'].length == 1 && this.filters['status'].length == 1) {
                        this.headers.find(x => x.value == 'actions').show = true;
                        this.showBulkUpdate = true;
                        this.bulkUpdateType = this.filters['status'][0];
                } else {
                    this.headers.find(x => x.value == 'actions').show = false;
                    this.bulkUpdateType =   '';
                    this.showBulkUpdate = false;
                    this.unselectAll();
                }
            }
        },
        addToFilter(value, field) {
            this.checkConditions(value, field);
            if (this.filters[field].includes(value)) {
                this.filters[field] = this.filters[field].filter((x) => x != value);
            } else {
                this.filters[field].push(value);
            }
            this.updateFilterValue++;
            // localStorage.setItem(
            //     "bookingFilters_" + this.$store.state.currentOrg.id,
            //     JSON.stringify(this.filters)
            // );
            let keys = Object.keys(this.filters);
            let result = [];
            keys.forEach((x) => {
                result = [
                ...result,
                ...this.filters[x].map((y) => ({
                    field: x,
                    fieldName: this.headers.find((z) => z.value == x).text,
                    value: y,
                })),
            ];
            });
            this.filteredValues = result;
            this.calculateFilteredResults();

        },
        async unselectAll() {
            return new Promise((resolve, reject) => {
                try {
                    this.files = this.files.map((x) => {
                        x.selected = false;
                        return x;
                    });
                    this.selectAllCheck = false;
                    this.canBulkUpdate = false;
                    resolve();
                } catch (error) {
                    console.log(error);
                    reject(error);
                }
            });
        },
        checkAllSelected() {
            if(this.files.length > 0) {
                if(this.files.some(x => x.selected)) {
                    this.canBulkUpdate = true
                } else {
                    this.canBulkUpdate = false
                }
            } else {
                this.canBulkUpdate = false
            }
        },
        selectAll() {
            if(this.selectAllCheck) {
                let selectedFiles = this.filesMap.map(x => {
                    return {
                        id: x.id,
                        fileNumber: x.fileNumber,
                    }
                })
                this.files = this.files.map(x => {
                    if(selectedFiles.some(y => y.id == x.id)) {
                        x.selected = true;
                    }
                    return x;
                });
            } else {
                this.files = this.files.map(x => {
                    x.selected = false;
                    return x;
                });
            }
            this.selectUpdateKey++
            this.checkAllSelected()
        },

        headerOptions(value) {
            let result = [
                ...new Set(
                this.files.map((x) => ({ name: x[value] ?? "(Blank)", value: x[value] }))
                ),
            ];
            let uniqueValues = [...new Set(result.map((x) => x.value))];
            if (this.searchHeaders[value]) {
                uniqueValues = uniqueValues.filter(
                (x) => x && x.toLowerCase().includes(this.searchHeaders[value].toLowerCase()));
            }
            result = uniqueValues.map((x) => result.find((y) => y.value === x));
            result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
            return result;
        },
        changeSortBy(value) {
            if (this.sortBy == value) {
                if (this.sortDesc) {
                    this.sortBy = null;
                    this.sortDesc = false;
                } else {
                    this.sortDesc = true;
                }
                } else {
                this.sortBy = value;
                this.sortDesc = false;
            }
        },
        async commentDoc(doc) {
            this.savingComment = true
            if (this.commentItem && !this.commentItem.commentType) {
                this.commentItem.commentType = 'Query'
            }
            console.log('this.commentItem', this.commentItem)
            let comment = {
                shipmentDocumentTypeId: doc.id,
                comment: this.commentItem.comment,
                commentType: this.commentItem.commentType,
                shipmentId: doc.shipmentId,
                createdById: this.$store.state.user.id,
            }
            let res = await this.$API.addShipmentDocumentTypeComment(comment)
            if(res) {
                comment.createdAt = res.createdAt
                comment.user = this.$store.state.user;
                comment.shipmentDocumentTypeComment = {
                    comment: this.commentItem.comment,
                    commentType: this.commentItem.commentType,
                }
                this.files = this.files.map(x => {
                    if(x.id == doc.id) {
                        x.comments.push(comment);
                    }
                    return x;
                });
            }   else {
                this.$message({
                    type: "error",
                    message: `Message could not go through. Please check your connection and try again.`,
                });
            }
            // if (res && this.commentItem.commentType == 'General' || this.commentItem.commentType == 'Invoice') {
            //   await this.getDocumentLog(this.documentItem.id)
            // }
            this.commentItem = {}
            this.savingComment = false
            this.commentModal = false
            },
        async viewShipment(item) {
            this.$router.push({
                path: "/shipment/" + item.shipmentReference,
            });
        },
        viewInvoiceNotReady() {
            console.log('TODO')
        },
        async getDocUsers() {
            let users = await this.$API.getDocUsers();
            if (users) {
                this.docUsers = users;
                sessionStorage.getItem('pinnedUsers') ? this.pinnedUsers = JSON.parse(sessionStorage.getItem('pinnedUsers')) : this.pinnedUsers = [];
            }
        },
        


        async getShipmentDocumentLog(id) {
            // console.log('getShipmentDocumentLog called with ID:', id);
            this.loadingComments = true;

            try {
                // console.log('Making API call to fetch comments for ID:', id); 
                let comments = await this.$API.getShipmentDocumentTypeComments(id);

                // console.log('API response for comments:', comments);

                this.chatOpen = id;
                comments = JSON.parse(JSON.stringify(comments));
                console.log('Processed comments data:', comments);

                if (comments) {
                    this.files = this.files.map(file => {
                        if (file.id === id) {
                            // console.log('Assigning comments to file with ID:', file.id);
                            file.comments = comments;
                            // console.log('Updated file:', file);
                        }
                        return file;
                    });
                }
            } catch (error) {
                console.error('Error fetching comments:', error);
            } finally {
                this.loadingComments = false;
                console.log('Loading comments set to false');
            }
        },


        async getCounts() {
            if(this.countsTimeout) {
                clearTimeout(this.countsTimeout);
            } 
            if(this.loadingCounts && this.countsRequest) {
                this.countsRequest.abort()
            }
            this.countsTimeout = setTimeout(async () => {
                this.countsRequest = new AbortController();
                const signal = this.countsRequest.signal
                this.loadingCounts = true;
                let [readyFiles, notReadyInvoice] = await Promise.allSettled([
                    this.$API.getReadyFileCount({userId: this.selectedUser.id}, signal),
                    this.$API.notReadyToInvoiceCount({userId: this.selectedUser.id}, signal)
                ]);
                this.readyFiles = readyFiles.value;
                this.notReadyInvoice = notReadyInvoice.value;
                this.loadingCounts = false;
            }, 1000);
        },
        async changeUser(user) {
            this.selectedUser = user;
            this.userModal = false
            this.getDocs();
            this.getCompletedForStash();
            this.getCounts();
            this.updateOnSelectedKey++;
            this.userRefreshKey ++
            sessionStorage.setItem('selectedUser', JSON.stringify(user));
        },
        filterSpecific(type) {
            console.log(type)
        },
        calculateFilteredResults() {
            let filterKeys = Object.keys(this.filters);
            let filterResult = [];
            filterKeys.forEach((x) => {
                filterResult = [
                ...filterResult,
                ...this.filters[x].map((y) => ({
                    field: x,
                    fieldName: this.headers.find((z) => z.value == x).text,
                    value: y,
                })),
            ];
            });
            this.filteredValues = filterResult;
            let result = [...this.files];
            let keys = Object.keys(this.filters);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                if (this.filters[key] && this.filters[key].length > 0) {
                result = result.filter((x) => this.filters[key].includes(x[key]));
                }
            }
            this.filteredResults = result;
            sessionStorage.setItem('doc_filters', JSON.stringify(this.filters));
        },
        switchFilter(filter) {
            if (['fivePlus', 'threeToFour', 'zeroToTwo', 'past','zeroToThree'].includes(filter)) {
                this.fivePlus = filter === 'fivePlus' ? !this.fivePlus : false;
                this.threeToFour = filter === 'threeToFour' ? !this.threeToFour : false;
                this.zeroToTwo = filter === 'zeroToTwo' ? !this.zeroToTwo : false;
                this.past = filter === 'past' ? !this.past : false;
                this.zeroToThree = filter === 'zeroToThree' ? !this.zeroToThree : false;
            } else if (['openFilesFilter', 'rejected', 'overdue', 'inDanger', 'withinKPI'].includes(filter)) {
                this.openFilesFilter = filter === 'openFilesFilter' ? !this.openFilesFilter : false;
                this.rejected = filter === 'rejected' ? !this.rejected : false;
                this.overdue = filter === 'overdue' ? !this.overdue : false;
                this.inDanger = filter === 'inDanger' ? !this.inDanger : false;
                this.withinKPI = filter === 'withinKPI' ? !this.withinKPI : false;
            }
            ['fivePlus','threeToFour','zeroToTwo','past','zeroToThree','openFilesFilter','rejected','overdue','inDanger','withinKPI',].forEach(x => {
                sessionStorage.setItem(x, this[x]);
            });
        },

 
        
        async getDocs() {
            if(this.fileTimeout) {
                clearTimeout(this.fileTimeout);
            }
            if(this.loadingFiles && this.fileRequest) {
                this.fileRequest.abort()
            }
            this.fileTimeout = setTimeout(async () => {
                this.fileRequest = new AbortController();
                const signal = this.fileRequest.signal
                this.loadingFiles = true;
                let result = await this.$API.getAllDocumentsForOrg({
                    userId: this.selectedUser.id,
                    documentationStatus: ["COMPLETED", "OPEN"],
                    showCompleted: this.showCompleted,
                }, signal);
                this.files = result.documents;
                this.shipmentCounts = result.shipmentCounts;
                this.calculateFilteredResults();
                this.loadingFiles = false;
            }, 1000);
        },
        async getCompletedForStash() {
            if(this.completedFileTimeout) {
                clearTimeout(this.completedFileTimeout);
            } 
            if(this.loadingCompletedFiles && this.completedFileRequest) {
                this.completedFileRequest.abort()
            }
            this.completedFileTimeout = setTimeout(async () => {
                this.completedFileRequest = new AbortController();
                const signal = this.completedFileRequest.signal
                this.loadingCompletedFiles = true;
                let result = await this.$API.getAllDocumentsForOrg({
                    userId: this.selectedUser.id,
                    documentationStatus: this.showCompleted ? ["COMPLETED", "OPEN"] : ["OPEN"],
                    showCompleted: true,
                }, signal);
                this.storedFiles = result.documents;
                this.loadingCompletedFiles = false;
            }, 1000);
        },
        // async updateKpi(document){
        // let result = await this.$API.getShipmentDocumentTypeDetail(document.id)

        // return result
        // },
        async viewDocument(document) {
            console.log('viewDoucment', document)
            if (document) {
                this.documentLoading = document.id
                let [doc, documentTasks, documentUploads, actions, shipment, bookings] = await Promise.allSettled([
                    this.$API.getShipmentDocumentTypeDetail(document.id),
                    this.$API.getShipmentDocumentTasks(document.id),
                    this.$API.getShipmentDocuments(document.shipment.id),
                    this.$API.getShipmentActions(document.shipment.id),
                    this.$API.getSingleShipment(document.shipment.shipmentReference),
                    this.$API.getShipmentBookings(document.shipment.shipmentFileId),
                ])
                this.document = doc.value
                console.log('document', this.document)
                if (this.document.variations) {
                    this.documentTypes = [this.document]
                }
                this.documentTasks = documentTasks.value
                console.log('documentTasks', this.documentTasks)
                this.documentUploads = documentUploads.value
                console.log('documentUploads', this.documentUploads)
                this.actions = actions.value
                console.log('actions', this.actions)
                this.shipment = shipment.value
                console.log('shipment', this.shipment)
                this.bookings = bookings.value
                console.log('bookings', this.bookings)
                this.fileNumber = document.shipment.shipmentFile.fileNumber
                console.log('fileNumber', this.fileNumber)
                setTimeout(() => {
                    this.openKey++
                }, 250)
                this.documentModal = true
                this.documentLoading = null
            }
        },


    }
}
</script>

<style scoped>
.primaryText--text :is(.v-chip .v-avatar.smallerAvatar) {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
}

.smallerAvatar {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
}

.searchInput :is(.v-input__append-inner) {
    margin-top: 2px !important;
}
.document {
    text-decoration: underline;
}
.rejected {
    color: var(--v-danger-base) !important
}
.overdue {
    color: var(--v-redPop-lighten1) !important
}
.inDanger {
    color: #dfaa49 !important
}
.withinKPI {
    color: var(--v-tertiary-base) !important
}

.chipCompleted {
    overflow: hidden;
    white-space: nowrap;
    max-width: 30px;
    font-size: 12px;
    transition: max-width 0.3s ease-in-out;
}

.chipCompleted:hover {
    max-width: 200px;
}

.filterHover {
    overflow: hidden;
    white-space: nowrap;
    max-width: 30px;
    font-size: 12px;
    transition: max-width 0.3s ease-in-out;
}

.filterHover:hover {
    max-width: 200px;
}
.textCompleted {
    visibility: hidden;
}

.bulkUpdateText {
    visibility: hidden;
}

.textFilters {
    visibility: hidden;
}

.searchChipWrap {
    width: 70%;
    display: grid;
    transition: width 0.3s ease-in-out;
}
.bulkUpdate {
    overflow: hidden;
    white-space: nowrap;
    max-width: 30px;
    font-size: 12px;
    transition: max-width 0.3s ease-in-out;
}
.bulkUpdate:hover {
    max-width: 200px;
}
.slightShrink {
    width: 65% !important;
}

.shrinkChip {
    width: 60% !important;
}
.shrinkChipPlus {
    width: 55% !important;
}
.shrinkMore {
    width: 57% !important;
}
.shrinkMorePlus {
    width: 50% !important;
}

.chipCompleted:hover .textCompleted {
    visibility: visible;
}

.filterHover:hover .textFilters {
    visibility: visible;
}

.bulkUpdate:hover .bulkUpdateText {
    visibility: visible;
}

.rotateRefresh {
    animation: rotate 0.5s linear infinite;
}
</style>
